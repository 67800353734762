.App {
  text-align: center;
}

/* .profile-card {
  background-image: url('bg.jpg');
  background-size: cover;
} */

.profile-card {
  /* filter: contrast(100%) brightness(100%); */
  background: linear-gradient(317deg, rgba(0, 0, 255, 1), rgba(0, 0, 0, 0)),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2.45' numOctaves='6' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}

/* .overlay {
  background: rgb(0, 13, 255);
  mix-blend-mode: multiply;
} */
